import analytics from '@/common/analytics';
import { Body, Button, H2, H4 } from '@cointracker/ui';
import {
  animateEarlyAccessContainer,
  animateSlideOnButton,
  animateSpinAroundButton,
  earlyAccessButtonBackground,
  enterpiseLogoContainer,
  enterpriseLanding,
  enterpriseLandingHeader,
  generalLedgerLogo,
  generalLedgersLogos,
  getEarlyAccessButton,
  integrationsContainer,
  link,
  midButtonSize,
  subheader,
  topSection,
} from './EnterpiseLanding.css';
import EnterpriseLogo from './images/CTEnterpriseLogo.svg?react';
import NetSuiteURL from './images/NetSuite.png?url';
import QuickBooksURL from './images/QBO.png?url';
import SageIntacct from './images/Sage.png?url';
import XeroURL from './images/Xero.png?url';

const IS_CHROMATIC = import.meta.env.PUBLIC_IS_CHROMATIC === 'true';
export const EnterpriseAddLanding = () => {
  const handleGetEarlyAccessLink = () => {
    analytics.track('Calendly clicked - Enterprise');
    window.location.href = 'https://calendly.com/d/cmqg-nfk-sr5';
  };
  const handleLoginLink = () => {
    analytics.track('Login clicked - Enterprise');
    window.location.href = `${import.meta.env.PUBLIC_ENTERPRISE_URL}/auth0/login`;
  };

  return (
    <section className={enterpriseLanding}>
      <div className={topSection}>
        <div className={enterpiseLogoContainer}>
          <EnterpriseLogo />
        </div>
        <Button
          variant="ghost"
          className={midButtonSize}
          onClick={handleLoginLink}
        >
          Sign in
        </Button>
      </div>
      <a
        target="_blank"
        rel="noreferrer"
        className={link}
        href="https://www.cointracker.com/blog/cointracker-enterprise"
      >
        Learn more →
      </a>
      <H2 as="h1" className={enterpriseLandingHeader}>
        The most accurate and intuitive crypto subledger
      </H2>
      <H4 as="h2" className={subheader}>
        Auto-reconcile millions of crypto transactions so you can close your
        books in minutes instead of weeks.
      </H4>
      <Button
        variant="primary"
        onClick={handleGetEarlyAccessLink}
        className={getEarlyAccessButton}
      >
        {!IS_CHROMATIC && (
          <>
            <div className={animateEarlyAccessContainer}>
              <div className={animateSlideOnButton}>
                <div className={animateSpinAroundButton} />
              </div>
            </div>
            <div className={earlyAccessButtonBackground}></div>
          </>
        )}
        Schedule a demo
      </Button>
      <div className={integrationsContainer}>
        <Body size="big">Integrates with</Body>
        <div className={generalLedgersLogos}>
          <img
            className={generalLedgerLogo}
            src={NetSuiteURL}
            alt="Net Suite"
          />
          <img
            className={generalLedgerLogo}
            src={QuickBooksURL}
            alt="QuickBooks"
          />
          <img className={generalLedgerLogo} src={XeroURL} alt="Xero" />
          <img
            className={generalLedgerLogo}
            src={SageIntacct}
            alt="Sage Intacct"
          />
        </div>
      </div>
    </section>
  );
};
